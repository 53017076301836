import React from "react";

export default function Footer(props) {
  return (
    <footer className="footer container-fluid">
      <div className="row pt-5">
        <div className="col-12 col-sm-4 d-flex flex-column align-items-center justify-content-around">
          <a href="https://cruxpool.com" className="logoLink">
            <img
              src="/footer/logo_footer.png"
              className="footer-logo"
              alt="Cruxpool logo"
            />
          </a>
          <p className="allRights">Cruxpool 2019 - All rights reserved</p>
          <div className="row align-items-center justify-content-center">
            <div className="col-3 socialBtn">
              <a
                href="https://discordapp.com/invite/fGKkwBj"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/footer/ic_discord@2x.png"
                  className="socialImg"
                  alt="Cruxpool logo"
                />
              </a>
            </div>
            <div className="col-3 socialBtn">
              <a
                href="https://www.youtube.com/channel/UC0X9LM7J93B_n-SLH1M2LEQ?"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/footer/ic_youtube@2x.png"
                  className="socialImg"
                  alt="Cruxpool logo"
                />
              </a>
            </div>
            <div className="col-3 socialBtn">
              <a
                href="https://twitter.com/cruxpool"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/footer/ic_twitter@2x.png"
                  className="socialImg"
                  alt="Cruxpool logo"
                />
              </a>
            </div>
            <div className="col-3 socialBtn">
              <a
                href="https://www.facebook.com/Cruxpool-546097915848122/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/footer/ic_facebook@2x.png"
                  className="socialImg"
                  alt="Cruxpool logo"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-8 row container-footer">
          <div className="col-6 col-sm-6 col-md-3 pool">
            <div className="listTitle">Our Pool</div>
            <div className="list">
              <a className="listItem" href="https://cruxpool.com/eth/">
                ETH
              </a>
              <a className="listItem" href="https://cruxpool.com/etc/">
                ETC
              </a>
              <a className="listItem" href="https://cruxpool.com/beam/">
                BEAM
              </a>
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-3 services">
            <div className="listTitle">Services</div>
            <div className="list">
              <a className="listItem" href="https://www.cruxpool.com/help/">
                Help
              </a>
              <a
                className="listItem"
                href="https://www.cruxpool.com/help/frequently-asked-questions/"
              >
                FAQ
              </a>
              <a className="listItem" href="https://api.cruxpool.com">
                API
              </a>
              <a
                className="listItem"
                href="https://www.cruxpool.com/help/discord-bot/"
              >
                Bot
              </a>
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-3 about">
            <div className="listTitle">About</div>
            <div className="list">
              <a className="listItem" href="https://blog.cruxpool.com">
                Blog
              </a>
              <a className="listItem" href="https://cruxpool.com/team">
                Team
              </a>
              <a className="listItem" href="https://cruxpool.com/media">
                Media ressources
              </a>
              <a
                className="listItem"
                href="https://www.cruxpool.com/help/contact-us/"
              >
                Contact us
              </a>
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-3 focus">
            <div className="listTitle">Focus on</div>
            <div className="list">
              <a
                className="listItem"
                href="https://iliium.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Iliium
              </a>
              <a
                className="listItem"
                href="https://blog.cruxpool.com/2019/05/20/how-to-mine-ethereum/"
              >
                Mining Guide
              </a>
              <a
                className="listItem"
                href="https://www.cruxpool.com/help/how-to-mine-with-nicehash-guide-2019/"
              >
                Nicehash
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
