import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

export default function App(props) {
  return (
    <div className="App">
      <Header />
      <SwaggerUI url="openapi.json" className="apiData" />
      <Footer />
    </div>
  );
}
