import React from "react";

export default function Header(props) {
  return (
    <nav className="navbar navbar-expand-lg border-bottom p-0">
      <div className="d-flex flex-grow-1">
        <a
          className="navbar-brand ml-4 d-none d-lg-inline-block"
          href="https://www.cruxpool.com"
        >
          <img
            src="/header/logo_topmenu.png"
            className="d-inline-block nav-logo-xl"
            alt="Cruxpool logo"
          />
        </a>
        <a
          className="navbar-brand-two m-2 mr-auto d-lg-none my-auto"
          href="https://www.cruxpool.com"
        >
          <img
            src="/header/logo.png"
            className="d-inline-block nav-logo-xs"
            alt="Cruxpool logo"
          />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#myNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
      <div className="collapse navbar-collapse flex-grow-1 " id="myNavbar">
        <ul className="navbar-nav ml-auto flex-nowrap">
          <li className="nav-item mx-3">
            <a
              href="https://www.cruxpool.com"
              className="nav-main nav-link menu-item nav-index"
            >
              Home
            </a>
          </li>
          <li className="nav-item mx-3">
            <a
              href="/"
              className="nav-main nav-link nav-active menu-item nav-index"
            >
              API
            </a>
          </li>
          <li className="nav-item mx-3">
            <a
              href="https://blog.cruxpool.com"
              className="nav-main nav-link menu-item nav-index"
            >
              Blog
            </a>
          </li>
          <li className="nav-item mx-3">
            <a
              href="https://www.cruxpool.com/help"
              className="nav-main nav-link menu-item nav-index"
            >
              Help
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
